import React, { useEffect, useState } from 'react';
import './Careers.css'; 
import axios from 'axios';
const jobListings = [
    {
        id: 1,
        title: 'Senior Accountant',
        location: 'San Francisco, CA',
        description: 'Responsible for managing financial records, preparing reports, and ensuring compliance with accounting standards.',
        requirements: [
            'Bachelor’s degree in Accounting or related field',
            '5+ years of experience in accounting',
            'Proficiency in accounting software (e.g., QuickBooks, Tally)',
        ],
    },
    {
        id: 2,
        title: 'Tax Consultant',
        location: 'New York, NY',
        description: 'Provide expert advice on tax-related matters and ensure compliance with tax laws.',
        requirements: [
            'Bachelor’s degree in Finance or Accounting',
            '3+ years of experience in tax consulting',
            'Strong knowledge of tax regulations and laws',
        ],
    },
    {
        id: 3,
        title: 'Tax Consultant',
        location: 'New York, NY',
        description: 'Provide expert advice on tax-related matters and ensure compliance with tax laws.',
        requirements: [
            'Bachelor’s degree in Finance or Accounting',
            '3+ years of experience in tax consulting',
            'Strong knowledge of tax regulations and laws',
        ],
    },
    {
        id: 4,
        title: 'Tax Consultant',
        location: 'New York, NY',
        description: 'Provide expert advice on tax-related matters and ensure compliance with tax laws.',
        requirements: [
            'Bachelor’s degree in Finance or Accounting',
            '3+ years of experience in tax consulting',
            'Strong knowledge of tax regulations and laws',
        ],
    },
    {
        id: 5,
        title: 'Tax Consultant',
        location: 'New York, NY',
        description: 'Provide expert advice on tax-related matters and ensure compliance with tax laws.',
        requirements: [
            'Bachelor’s degree in Finance or Accounting',
            '3+ years of experience in tax consulting',
            'Strong knowledge of tax regulations and laws',
        ],
    },
];

const Careers = () => {
    const [jobListings, setJobListings] = useState([]);
    const [showApplication, setShowApplication] = useState(false);
    const [currentJob, setCurrentJob] = useState(null);
    const [applicationData, setApplicationData] = useState({
        name: '',
        email: '',
        resume: '',
        whyHireYou: '', 
    });


    useEffect(() => {
        fetch('https://cabackend.onrender.com/career/getAllCareers')
            .then(response => response.json())
            .then(data => setJobListings(data))
            .catch(error => console.error('Error fetching job listings:', error));
    }, []);


    const handleApplyClick = (job) => {
        setCurrentJob(job);
        setShowApplication(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setApplicationData((prev) => ({ ...prev, [name]: value }));
    };

    const handleFileChange = (e) => {
        setApplicationData((prev) => ({ ...prev, resume: e.target.files[0] }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const formData = new FormData();
        formData.append('name', applicationData.name);
        formData.append('email', applicationData.email);
        formData.append('resumeFilePath', applicationData.resume);
        formData.append('whyHireYou', applicationData.whyHireYou);

       
        try {
            const response = await axios.post('http://localhost:5000/resume/create', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            if (response.status === 201) {
                alert('Application submitted successfully!');
                setShowApplication(false); 
            } else {
                alert('Failed to submit the application.');
            }
        } catch (error) {
            console.error('Error submitting the application:', error);
            alert('An error occurred. Please try again.');
        }
    };
    
    
    
    return (
        <div className="careers-page">
            <h1>Careers</h1>
            <div className="job-listings">
                {/* {jobListings.map((job) => (
                    <div className="job-item" key={job.id}>
                        <h2>{job.title}</h2>
                        <p><strong>Location:</strong> {job.location}</p>
                        <p><strong>Description:</strong> {job.description}</p>
                        <p><strong>Requirements:</strong></p>
                        <ul>
                            {job.requirements.map((req, index) => (
                                <li key={index}>{req}</li>
                            ))}
                        </ul>
                        <button onClick={() => handleApplyClick(job)}>Apply Now</button>
                    </div>
                ))} */}
                {jobListings.map((job) => (
                    <div className="job-item" key={job.id}>
                        <h2>{job.title}</h2>
                        <p><strong>Location:</strong> {job.location}</p>
                        <p><strong>Description:</strong> {job.description}</p>
                        <p><strong>Requirements:</strong></p>
                        <ul>
                            {job.requirements.map((req, index) => (
                                <li key={index}>{req}</li>
                            ))}
                        </ul>
                        <button onClick={() => handleApplyClick(job)}>Apply Now</button>
                    </div>
                ))}

            </div>
            {showApplication && currentJob && (
            <div className="application-modal">
                <div className="modal-content">
                    <span className="close-button" onClick={() => setShowApplication(false)}>&times;</span>
                    <h2>Apply for {currentJob.title}</h2>
                    <form onSubmit={handleSubmit}>
                        <label>
                            Full Name
                            <input
                                type="text"
                                name="name"
                                placeholder="Full Name"
                                value={applicationData.name}
                                onChange={handleChange}
                                required
                            />
                        </label>
                        <label>
                            Email Address
                            <input
                                type="email"
                                name="email"
                                placeholder="Email Address"
                                value={applicationData.email}
                                onChange={handleChange}
                                required
                            />
                        </label>
                        <label>
                            Resume
                            <input
                            type="file"
                            name="resumeFilePath"
                            accept=".pdf, .doc, .docx"
                            onChange={handleFileChange}
                            required
                            />

                        </label>
                        <label>
                            Why Should We Hire You?
                            <textarea
                                name="whyHireYou"
                                placeholder="Describe why you are a great fit for this role."
                                value={applicationData.whyHireYou}
                                onChange={handleChange}
                                rows="4"
                                required
                            />
                        </label>
                        <button type="submit">Submit Application</button>
                    </form>
                </div>
            </div>
        )}
        </div>
    );
};

export default Careers;
