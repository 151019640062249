import React from 'react';
import './About.css';
import teamImage from '../../assets/aboutussectionhero.jpg';

const About = () => {
  return (
    <>
      <section className="about-us-banner">
        <img src={teamImage} alt="Team Banner" className="banner-image" />
      </section>

      <section className="intro">
        <div className="container">
          <div className="intro-content" data-aos="fade-up-right">
            <h1>About Us</h1>
            <div className="intro-text">
              <p>
              C S J Business Solutions Private Limited, was incorporated under Companies Act, 2013 and approved by The Institute of Chartered Accountants of India , is associated concern of M/s  C S J & Co. (Chartered Accountants), is a well-established Chartered Accountancy firm with a strong reputation for delivering high-quality financial and advisory services. With 14 years of dedicated service, we have grown into a trusted partner for businesses and individuals alike, helping them navigate the complexities of finance, taxation, and compliance.</p>
             <p>Our firm is built on a foundation of integrity, professionalism, and client-centric values. Over the years, we have developed a deep understanding of various industries, enabling us to provide tailored solutions that drive growth and efficiency. Our team of experienced professionals is committed to upholding the highest standards of service, ensuring that our clients receive the expertise and support they need to achieve their financial goals.</p>
             <p>We’re always evolving and adding new services to better meet our clients' needs. We take pride in our progress and are committed to delivering exceptional value and building lasting, trust-based relationships.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class="why-choose-us">
        <h2>Why Choose Us for Your Accounting Needs?</h2>
        <ul>
            <li>
                <h3>Expert Team</h3>
                <p>Our team of certified accountants brings years of experience across various industries. We stay up-to-date with the latest accounting standards, tax laws, and financial regulations to ensure your business is always in compliance.</p>
            </li>
            <li>
                <h3>Tailored Solutions</h3>
                <p>We understand that every business is unique. That's why we provide customized accounting services designed to meet your specific needs, from bookkeeping and payroll management to tax planning and financial analysis.</p>
            </li>
            <li>
                <h3>Transparent Pricing</h3>
                <p>No hidden fees or surprise costs. Our pricing is straightforward, competitive, and aligned with the value we deliver, so you can budget with confidence.</p>
            </li>
            <li>
                <h3>Advanced Technology</h3>
                <p>We use cutting-edge accounting software and tools to streamline processes, reduce errors, and provide real-time financial insights, giving you a clear picture of your financial health at all times.</p>
            </li>
            <li>
                <h3>Proactive Approach</h3>
                <p>Beyond crunching numbers, we act as your strategic partners. We identify opportunities for cost savings, optimize your tax strategies, and provide actionable insights to help grow your business.</p>
            </li>
            <li>
                <h3>Personalized Service</h3>
                <p>Our client-centric approach means you'll always have direct access to a dedicated account manager who understands your business and is committed to your success.</p>
            </li>
            <li>
                <h3>Trust and Confidentiality</h3>
                <p>Your financial data is safe with us. We adhere to strict confidentiality standards and employ robust data security measures to protect your sensitive information.</p>
            </li>
            <li>
                <h3>Proven Track Record</h3>
                <p>We have a solid reputation for delivering reliable, accurate, and timely accounting services. Our satisfied clients and long-standing relationships speak to our commitment to excellence.</p>
            </li>
        </ul>

        <h2>Why Choose Us for Your Online Accounting Needs?</h2>
        <ul>
            <li>
                <h3>Convenience and Accessibility</h3>
                <p>Our fully online accounting services provide you with 24/7 access to your financial data from anywhere in the world. No need for in-person meetings or paperwork – simply log in and manage your finances whenever it suits you.</p>
            </li>
            <li>
                <h3>Seamless Integration</h3>
                <p>We integrate seamlessly with popular accounting software and platforms, ensuring your existing financial systems are streamlined and up to date. Our services are compatible with all major software, allowing for easy data transfer and management.</p>
            </li>
            <li>
                <h3>Secure and Reliable</h3>
                <p>We use state-of-the-art encryption and secure cloud storage to protect your financial data. Our platform is designed with robust security protocols to ensure your information remains confidential and protected from unauthorized access.</p>
            </li>
            <li>
                <h3>Real-Time Insights</h3>
                <p>Get instant access to real-time financial reports and dashboards. Our advanced tools provide you with up-to-date insights into your cash flow, expenses, and overall financial health, helping you make informed decisions quickly.</p>
            </li>
            <li>
                <h3>Affordable and Flexible Plans</h3>
                <p>Our online services are more cost-effective than traditional accounting firms. We offer flexible pricing plans tailored to your business size and needs, so you pay only for what you use – no hidden fees or long-term contracts.</p>
            </li>
            <li>
                <h3>Dedicated Support Team</h3>
                <p>Even though we operate online, we provide personalized support through multiple channels – email, phone, or live chat. Our team of experienced accountants is always available to assist you with any questions or concerns.</p>
            </li>
            <li>
                <h3>Scalable Solutions</h3>
                <p>Whether you're a freelancer, a startup, or an established business, our services can scale with you. As your business grows, our online solutions adapt to meet your evolving accounting needs without the need for major overhauls or disruptions.</p>
            </li>
            <li>
                <h3>Fast Onboarding and Easy Setup</h3>
                <p>Getting started with us is quick and hassle-free. Our intuitive onboarding process and easy-to-use platform mean you can start managing your accounts online with minimal setup and training.</p>
            </li>
            <li>
                <h3>Eco-Friendly Approach</h3>
                <p>By choosing our online services, you reduce your carbon footprint. Our digital-first approach eliminates the need for paper-based records, contributing to a more sustainable future.</p>
            </li>
        </ul>
    </section>

      <section className="company-info">
        <div className="container">
          <div className="info-block">
            <h2>Company Name</h2>
            <p>C S J Business Solutions Private Limited</p>
          </div>
          <div className="info-block">
            <h2>Address</h2>
            <p>106, Shrivardhan Complex, 4, R. N. T. Marg, Indore</p>
          </div>
          <div className="info-block">
            <h2>Contact</h2>
            <p>0731-4030705, 8109846688</p>
            <p>Email: <a href="mailto:csjbizsolutions@gmail.com">csjbizsolutions@gmail.com</a></p>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
