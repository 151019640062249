import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo1 from '../../assets/logonew1.png'; 
import logo2 from '../../assets/logonew.png';
import facebook from '../../assets/social/facebook.png';
import Instagram from '../../assets/social/instagram.png';
import whatsapp from '../../assets/social/whatsapp.png';
import linkedin from '../../assets/social/linkedin.png';
import login from '../../assets/login-removebg-preview.png';
import Home from '../../assets/home-removebg-preview.png';
import About from '../../assets/about-removebg-preview.png';
import Service from '../../assets/service-removebg-preview.png';
import Contact from '../../assets/contact-removebg-preview.png';
import Career from '../../assets/career-removebg-preview.png';


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuItemClick = () => {
    setIsOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className='logo'>
          <img src={logo1} alt="Logo" className="navbar-logo" />
          <img src={logo2} alt="Logo" className="navbar-logo" />
        </div>
        <div className="navbar-toggle" onClick={toggleNavbar}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
        <ul className={`navbar-menu ${isOpen ? 'active' : ''}`}>
          <li><Link to="/" onClick={handleMenuItemClick}><span>Home</span></Link></li>
          <li><Link to="/about" onClick={handleMenuItemClick}><span>About</span></Link></li>
          <li><Link to="/team-members" onClick={handleMenuItemClick}>Team Members</Link></li>
          <li><Link to="/service" onClick={handleMenuItemClick}><span>Service</span></Link></li>
          <li><Link to="/contact" onClick={handleMenuItemClick}><span>Contact</span></Link></li>
          <li><Link to="/careers" onClick={handleMenuItemClick}><span>Careers</span></Link></li>
          <li className="social-icon">
          <a href="https://www.facebook.com/share/4tZqbDB8cSJNT14p/?mibextid=qi2Omg" target="_blank" rel="noopener noreferrer" onClick={handleMenuItemClick}>
              <img src={facebook} alt="Facebook Icon" className="nav-icon"/>
          </a>
          </li>
          <li className="social-icon">
              <a href="https://wa.me/918109846688" target="_blank" rel="noopener noreferrer" onClick={handleMenuItemClick}>
                  <img src={whatsapp} alt="WhatsApp Icon" className="nav-icon"/>
              </a>
          </li>
          <li className="social-icon">
              <a href="https://www.instagram.com/csjbizsolutions?igsh=bTF1c2pxN2V3bmU5" target="_blank" rel="noopener noreferrer" onClick={handleMenuItemClick}>
                  <img src={Instagram} alt="Instagram Icon" className="nav-icon"/>
              </a>
          </li>
          <li className="social-icon">
              <a href="https://www.linkedin.com/in/csj-business-solutions-9824482b3?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank" rel="noopener noreferrer" onClick={handleMenuItemClick}>
                  <img src={linkedin} alt="LinkedIn Icon" className="nav-icon"/>
              </a>
          </li>
        </ul>
        <a href="http://csjbiz.ddns.net:8080" target="_blank" rel="noopener noreferrer" className="login-button"><img src={login}/></a>
      </div>
    </nav>
  );
};

export default Navbar;
