import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './Teammembersdetails.css'; 

const TeamMemberDetails = () => {
    const { id } = useParams();
    const [teamMember, setTeamMember] = useState(null);

    useEffect(() => {
        const fetchTeamMember = async () => {
            try {
                const response = await axios.get(`https://cabackend.onrender.com/team/getTeamMemberById/${id}`);
                setTeamMember(response.data.teamMember);
            } catch (error) {
                console.error('Error fetching team member:', error);
            }
        };

        fetchTeamMember();
    }, [id]);

    if (!teamMember) {
        return <div>Loading...</div>;
    }

    return (
        <div className="team-member-details">
            <div className='teammember'>  
            <div className="team-member-image-container">
                <img src={teamMember.image} alt={teamMember.name} className="team-member-images" />
            </div>
            <div className="team-member-content">
                <h2>{teamMember.name}</h2>
                <p className="team-member-role">{teamMember.role}</p>
            </div>
            </div>
            <p className="team-member-bio">{teamMember.description}</p>
        </div>
    );
};

export default TeamMemberDetails;
