import React, { useState } from 'react';
import axios from 'axios';
import './Contact.css';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const [status, setStatus] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://cabackend.onrender.com/contact/createContact', formData);
            if (response.status === 200) {
                setStatus('Message sent successfully!');
                setFormData({
                    name: '',
                    email: '',
                    subject: '',
                    message: ''
                });
            } else {
                setStatus('Failed to send message. Please try again later.');
            }
        } catch (error) {
            setStatus('An error occurred. Please try again later.');
        }
    };

    return (
        <div className="contact-page">
            <h1>Contact Us</h1>
            <div className="contact-container">
                <div className="contact-info">
                    <div className="info-item">
                        <i className="icon location"></i>
                        <div>
                            <h2>Location:</h2>
                            <p>106, Shrivardhan Complex, 4, R. N. T. Marg<br />Indore 452001 (M.P.), India.</p>
                        </div>
                    </div>
                    <div className="info-item">
                        <i className="icon email"></i>
                        <div>
                            <h2>Email:</h2>
                            <p>csjbizsolutions@gmail.com</p>
                        </div>
                    </div>
                    <div className="info-item">
                        <i className="icon call"></i>
                        <div>
                            <h2>Call:</h2>
                            <p>0731-4030705</p>
                        </div>
                    </div>
                    <div className="info-item">
                        <i className="icon mobile"></i>
                        <div>
                            <h2>Mobile:</h2>
                            <p>8109846688<br />Whatsapp: +91 8109846688</p>
                        </div>
                    </div>
                </div>
                <form className="contact-form" onSubmit={handleSubmit}>
                    <div className="form-row">
                        <input 
                            type="text" 
                            name="name" 
                            placeholder="Your Name" 
                            value={formData.name} 
                            onChange={handleChange} 
                            required 
                        />
                        <input 
                            type="email" 
                            name="email" 
                            placeholder="Your Email" 
                            value={formData.email} 
                            onChange={handleChange} 
                            required 
                        />
                    </div>
                    <input 
                        type="text" 
                        name="subject" 
                        placeholder="Subject" 
                        value={formData.subject} 
                        onChange={handleChange} 
                        required 
                    />
                    <textarea 
                        name="message" 
                        placeholder="Message" 
                        value={formData.message} 
                        onChange={handleChange} 
                        required 
                    ></textarea>
                    <button type="submit">Send Message</button>
                    {status && <p className="status-message">{status}</p>}
                </form>
            </div>
            <div className="map-container">
                <iframe
                    title="Google Map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58876.521102940766!2d75.84490634417034!3d22.7363216331628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f20.1!3m3!1m2!1s0x3962fda452965a8b%3A0x7447289f0fa3a8a3!2sC%20S%20J%20Business%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1724872371390!5m2!1sen!2sin"
                    width="100%"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                ></iframe>
            </div>
        </div>
    );
};

export default Contact;
