import React, { useEffect, useState } from 'react';
import './Home.css'; 
import Slider from 'react-slick'; 
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Hero from '../../assets/Hero-removebg-preview.png';
import About from '../../assets/about.jpg';
import Zoho from '../../assets/software/zoho.jpg';
import Sageone from '../../assets/software/sage.jpg';
import Myob from '../../assets/software/myob.png';
import Sap from '../../assets/software/sap.jpg';
import Xero from '../../assets/software/Xero.png';
import QuickBooks from '../../assets/software/quick.png';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Hero1 from '../../assets/background4.jpg';
import Hero2 from '../../assets/background5.jpg';
import Hero3 from '../../assets/background3.jpg';
import Phone from '../../assets/PHONENEW-removebg-preview.png';
import Dollex from '../../assets/dollex.png';
import Cleaning from '../../assets/cleaning.jpeg';
import Aayam from '../../assets/Aayam.png';
import Tally from '../../assets/tally.jpeg';
import Havisha from '../../assets/Havihsa.jpeg';
// import axios from 'axios';

const Home = () => {
    const [teamMembers, setTeamMembers] = useState([]);
    const [services, setServices] = useState([]);
    const [carouselImages, setCarouselImages] = useState([
       Hero1,
       Hero2,
       Hero3,
    ]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isMobileView, setIsMobileView] = useState(false);
    const [testimonials, setTestimonials] = useState([]);
    const [message, setMessage] = useState([]);
    useEffect(() => {
      // Fetch testimonials from your API or define them statically for now
      // const fetchTestimonials = async () => {
      //     try {
      //         // Replace with your actual API call
      //         const response = await axios.get('https://your-api-endpoint.com/testimonials');
      //         setTestimonials(response.data.testimonials);
      //     } catch (error) {
      //         console.error('Error fetching testimonials:', error);
      //     }
      // };

      // fetchTestimonials();
      const heroMessages = [
        {message: "Streamline Your Finances with Expert Online Accounting Services."},
        {message: "Cloud-Based Accounting for Real-Time Financial Insights."},
        {message: "Accurate, Reliable, and Secure Online Bookkeeping Solutions."},
        {message:"Affordable Online Accounting for Small and Medium Businesses."},
        {message:"Simplify Your Financial Management with Our Online Platform."},
        {message:"Dedicated Accountants Providing 24/7 Support and Advice."},
        {message:"Harness the Power of Technology to Maximize Your Profits."},
        {message:"Efficient Tax Preparation and Filing Services Online."},
        {message:"Your Trusted Partner in Financial Growth and Compliance."},
        {message:"Comprehensive Financial Services Tailored to Your Needs."}
      ];
      setMessage(heroMessages);
    //   const staticTestimonials = [
    //     { message: "This company is amazing! They helped me with my tax issues efficiently.", author: "Bright Cleaning Service", image:Cleaning },
    //     { message: "Professional and reliable services. Highly recommended!", author: "Dollex Agrotech Ltd.", image:Dollex },
    //     { message: "Great customer service and expert advice.", author: "Aayam Career Institute",image:Aayam },
    //     {message:"The team’s expertise and dedication have significantly improved our financial processes and compliance", author:"Havisha Polymers Pvt. Ltd.",image:Havisha},
    // ];
    // setTestimonials(staticTestimonials);
    
    const fetchTestimonials = async () => {
        try {
          const response = await axios.get('https://cabackend.onrender.com/testimonial/getAllTestimonials');
          
          console.log("response",response)
          setTestimonials(response.data.testimonials);
        } catch (error) {
          console.error("Error fetching testimonials:", error);
        }
      };
  
      fetchTestimonials();

  }, []);

  const testimonialSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
};
const softwareSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  speed: 500,
  responsive: [
    {
        breakpoint: 1024,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
        }
    },
    {
        breakpoint: 600,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        }
    }
],
};


    useEffect(() => {
      // Function to check screen width
      const handleResize = () => {
        setIsMobileView(window.innerWidth <= 768); // Mobile view if screen width is 768px or less
      };
  
      handleResize(); // Check on component mount
      window.addEventListener("resize", handleResize); // Add event listener
  
      // Cleanup event listener on component unmount
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
      const handleResize = () => {
        setIsMobileView(window.innerWidth <= 768); // Mobile view if screen width is 768px or less
      };
  
      handleResize(); // Check on component mount
      window.addEventListener("resize", handleResize); // Add event listener
  
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const fetchTeamMembers = async () => {
            try {
                const response = await axios.get('https://cabackend.onrender.com/team/getAllTeamMembers');
                setTeamMembers(response.data.teamMembers); 
            } catch (error) {
                console.error('Error fetching team members:', error);
            }
        };

        fetchTeamMembers();
    }, []);
    
    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await fetch(
                    "https://cabackend.onrender.com/service/getAllServices"
                );
                const data = await response.json();
                setServices(data.services);
            } catch (error) {
                console.error("Error fetching services:", error);
            }
        };

        fetchServices();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => 
                (prevIndex + 1) % carouselImages.length
            );
        }, 5000); // Change image every 5 seconds

        return () => clearInterval(interval);
    }, [carouselImages.length]);

    const softwareList = [
        { name: 'QuickBooks', imgSrc: QuickBooks },
        { name: 'Tally', imgSrc: Tally },
        { name: 'Zoho Books', imgSrc: Zoho },
        { name: 'Myob', imgSrc: Myob },
        { name: 'Xero', imgSrc: Xero },
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        responsive: [
          {
              breakpoint: 1024,
              settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
              }
          },
          {
              breakpoint: 600,
              settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
              }
          }
        ],
        // nextArrow: <CustomNextArrow />,
        // prevArrow: <CustomPrevArrow />
    };
    const setting = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        responsive: [
          {
              breakpoint: 1024,
              settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
              }
          },
          {
              breakpoint: 600,
              settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
              }
          }
        ],
        // nextArrow: <CustomNextArrow />,
        // prevArrow: <CustomPrevArrow />
    };
    function CustomNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <button
                className={`slider-button slider-button-next ${className}`}
                style={{ ...style }}
                onClick={onClick}
            >
                Next
            </button>
        );
    }

    function CustomPrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <button
                className={`slider-button slider-button-prev ${className}`}
                style={{ ...style }}
                onClick={onClick}
            >
                Prev
            </button>
        );
    }

    return (
        <>
           <div className="hero-container">
            <div className="overlay"></div>
            {carouselImages.map((image, index) => (
                <img
                    key={index}
                    src={image}
                    alt={`Carousel image ${index + 1}`}
                    className={`carousel-image ${index === currentImageIndex ? 'active' : ''}`}
                />
            ))}
            <div className="hero-content" data-aos="fade-up-right">
                <p className="welcome">Welcome To CSJ Business Solutions</p>
                {/* <h1>Premier Chartered Accounting Services</h1> */}
                <Slider {...setting}>
                {message.map((message) => (
                <p>{message.message}</p>
            ))}
            </Slider>
                {/* <p>At ANG, we offer comprehensive services including Income Tax, International Tax and Transfer Pricing, GST, Bookkeeping & Accounting (Tally & QuickBooks), Auditing, India Entry Services, Overseas Business Setup, and more.</p> */}

                <button className="contact-button" ><Link to="/contact">Contact Us</Link></button>
            </div>
            {/* <div className="ad-container">
                <div className="ad-icon">
                    <img src={Phone} alt="Experience icon" />
                </div>
                <div className="ad-content">
                    <p><strong>Contact:</strong> 0731-4030705, 8109846688</p>
                    <p><strong>Email:</strong> csjbizsolutions@gmail.com</p>
                </div>
            </div> */}
        </div>





            <div className="aboutus-container">
                <div className="aboutus-content" data-aos="fade-down-right">
                    <h1>Know More About Us</h1>
                    <p>
                        We are dedicated to providing exceptional services to our clients, focusing on delivering quality and value. Our team of professionals is here to help you achieve your business goals with expertise and experience.
                    </p>
                    <button className="aboutus-button"><Link to="/about">Learn More</Link></button>
                </div>
                <div className="aboutus-image" data-aos="fade-down-left">
                    <img src={About} alt="About Us Graphic"/>
                </div>
            </div>

    
    <section className="services-section">
      <div className="container">
        <h2 className="section-title">Our Services</h2>
       
          {/* <Slider {...settings}>
            {services.map((service, index) => (
              <div className="service-card" key={index}>
                <Link to="/service">
                  <img
                    src={service.image}
                    alt={service.title}
                    className="service-image"
                  />
                </Link>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
            ))}
          </Slider> */}
        
          <div className="services-grid">
            {services.map((service, index) => (
              <div className="service-card" key={index}>
                <Link to="/service">
                  <img
                    src={service.image}
                    alt={service.title}
                    className="service-image"
                  />
                </Link>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
            ))}
          </div>
       
      </div>
           </section>

            <section className="team-section">
                <div className="container">
                    <h2 className="section-title">Meet Our Team</h2>
                    <Slider {...settings}>
                        {teamMembers.map((member, index) => (
                         
                            <div className="team-card" key={index}>
                              <Link to={`/team-members/${member._id}`}>
                                <img src={member.image} alt={member.name} className="team-image"/>
                                <h3>{member.name}</h3>
                                <p>{member.role}</p>
                                </Link>
                            </div>
                           
                        ))}
                    </Slider>
                </div>
            </section>

            <section className="software-section">
              <div className="container">
                <h2 className="section-title">Software We Use</h2>
                <Slider {...softwareSettings}>
                    {softwareList.map((software, index) => (
                      <div style={{margin:"10px"}}>
                      <div className="software-card" key={index}>
                        <img
                          src={software.imgSrc}
                          alt={software.name}
                          className="software-image"
                        /> 
                      </div>
                      </div>
                    ))}
                  </Slider>
              </div>
            </section>

            {/* Testimonials Section */}
            <section className="testimonials-section">
                <div className="container">
                    <h2 className="section-title">Our Clients</h2>
                    <Slider {...testimonialSettings}>
                        {testimonials.map((testimonial, index) => (
                            <div className="testimonial-card" key={index}>
                               <img src={testimonial.logo} alt={testimonial.author} className="testimonial-image"/>
                                {/* <p className="testimonial-text">"{testimonial.message}"</p> */}
                                {/* <h3 className="testimonial-author">- {testimonial.author}</h3> */}
                            </div>
                        ))}
                    </Slider>
                </div>
            </section>

            <section className="contact-section">
                <div className="container">
                    <h2 className="section-title">Contact Us</h2>
                    <div className="contact-content">
                        <div className="contact-details" data-aos="fade-right">
                            {/* <h3>Get in Touch</h3> */}
                            <p><strong>Address:</strong> 106, Shrivardhan Complex, 4, R. N. T. Marg, Indore</p>
                            <p><strong>Phone:</strong> 0731-4030705, 8109846688</p>
                            <p><strong>Email:</strong> csjbizsolutions@gmail.com</p>
                            <p><strong>Working Hours:</strong> Mon - Fri, 11:00 AM - 7:00 PM</p>
                        </div>
                        <div className="contact-map" data-aos="fade-left">
                            <iframe 
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58876.521102940766!2d75.84490634417034!3d22.7363216331628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f2.1!3m3!1m2!1s0x3962fda452965a8b%3A0x7447289f0fa3a8a3!2sC%20S%20J%20Business%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1724872371390!5m2!1sen!2sin"
                                width="600" 
                                height="600" 
                                style={{ border: 0, width: '100%', height: '100%' }} 
                                allowFullScreen="" 
                                aria-hidden="false" 
                                tabIndex="0"
                                title="Google Maps">
                            </iframe>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Home;