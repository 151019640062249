import React from 'react';
import './footer.css'; 
import Logo from '../../assets/logo footer.png';
import facebook from '../../assets/social/facebook.png';
import Instagram from '../../assets/social/instagram.png';
import Twitter from '../../assets/social/twitter.png';
import whatsapp from '../../assets/social/whatsapp.png';
import linkedin from '../../assets/social/linkedin.png';
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-logo">
            <img src={Logo} alt="Company Logo" className="logo"/>
            <p>We provide quality services to our clients with commitment to uphold high standards of honesty, ethics and integrity.</p>
          </div>
          <div className="footer-links">
            <h4>Quick Links</h4>
            <ul>
              <li><a href="/service">Services</a></li>
              <li><a href="/team-members">Our Team</a></li>
              <li><a href="/contact">Contact Us</a></li>
              <li><a href='/careers'> Careers</a></li>
              <li><a href='/about'> About</a></li>
            </ul>
          </div>
          <div className="footer-contact">
            <h4>Contact Us</h4>
            <p>Email: csjbizsolutions@gmail.com</p>
            <p>Phone:0731-4030705, 8109846688</p>
            <p>106, Shrivardhan Complex, 4, R. N. T. Marg, Indore</p>
            <ul>
            <li>
            <a href="https://www.facebook.com/share/4tZqbDB8cSJNT14p/?mibextid=qi2Omg" target="_blank" rel="noopener noreferrer">
              <img src={facebook} alt="Facebook Icon" className="nav-icons"/>
            </a>
          </li>
          <li>
            <a href="https://wa.me/918109846688" target="_blank" rel="noopener noreferrer">
              <img src={whatsapp} alt="WhatsApp Icon" className="nav-icons"/>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/csjbizsolutions?igsh=bTF1c2pxN2V3bmU5" target="_blank" rel="noopener noreferrer">
              <img src={Instagram} alt="Instagram Icon" className="nav-icons"/>
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/csj-business-solutions-9824482b3?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank" rel="noopener noreferrer">
              <img src={linkedin} alt="LinkedIn Icon" className="nav-icons"/>
            </a>
          </li>



          
            </ul>
          </div>
        </div>
        {/* <Link to="/login" className="login-buttons">Login</Link> */}
        <div className="footer-bottom">
          <p>&copy; 2024 CSJ Business Solutions. All rights reserved.</p>
          <p>Made with ❤️ by VIHAR Infotech</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
