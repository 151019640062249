import React from 'react';
import { Link } from 'react-router-dom';
import './bottomnavigatio.css';

// Import your icon images
import HomeIcon from '../../assets/home-removebg-preview.png';
import MyTripsIcon from '../../assets/about-removebg-preview.png';
import OffersIcon from '../../assets/service-removebg-preview.png';
import WhereToGoIcon from '../../assets/contact-removebg-preview.png';
import WalletIcon from '../../assets/career-removebg-preview.png';
import team from '../../assets/team-removebg-preview.png';

const BottomNavigation = () => {
  return (
    <nav className="bottom-nav">
      <ul>
        <li>
          <Link to="/">
            <img src={HomeIcon} alt="Home" className="nav-icons" />
            <span>Home</span>
          </Link>
        </li>
        <li>
          <Link to="/about">
            <img src={MyTripsIcon} alt="My Trips" className="nav-icons" />
            <span>About</span>
          </Link>
        </li>
        <li>
          <Link to="/service">
            <img src={OffersIcon} alt="Offers" className="nav-icons" />
            <span>Services</span>
          </Link>
        </li>
        <li>
          <Link to="/team-members">
            <img src={team} alt="Where To Go" className="nav-icons" />
            <span>Team</span>
          </Link>
        </li>
        <li>
          <Link to="/contact">
            <img src={WhereToGoIcon} alt="Where To Go" className="nav-icons" />
            <span>Contact</span>
          </Link>
        </li>
        <li>
          <Link to="/careers">
            <img src={WalletIcon} alt="Wallet" className="nav-icons" />
            <span>Career</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default BottomNavigation;