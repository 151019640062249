import React, { useEffect, useState } from 'react';
import './TeamMembers.css'; 
import axios from 'axios';
import { Link } from 'react-router-dom';
import Hero from '../../assets/Dalle.jpg';

const TeamMembers = () => {
    const [teamMembers, setTeamMembers] = useState([]);

    useEffect(() => {
        const fetchTeamMembers = async () => {
            try {
                const response = await axios.get('https://cabackend.onrender.com/team/getAllTeamMembers');
                setTeamMembers(response.data.teamMembers); 
            } catch (error) {
                console.error('Error fetching team members:', error);
            }
        };

        fetchTeamMembers();
    }, []);

    const [expanded, setExpanded] = useState({});

    const handleReadMore = (index) => {
        setExpanded(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    return (
        <div className='containers'>
            <img src={Hero} alt="Hero"/>
            <div className="team-page">
                <div className="team-section">
                    <h1>Team Members</h1>
                    <div className="team-members">
                        {Array.isArray(teamMembers) && teamMembers.map((member, index) => (
                            <div className="team-member" key={index}>
                                <Link to={`/team-members/${member._id}`} style={{display:"flex"}}>
                                    <div style={{marginTop:"-75px"}}>
                                        <div className="team-member-image">
                                            <img src={member.image || 'path_to_placeholder_image'} alt={member.name} />
                                        </div>
                                        <div className="team-member-info">
                                            <h2>{member.name}</h2>
                                            <p>{member.role}</p>
                                        </div>
                                    </div>
                                </Link>
                                <div className={`description ${expanded[index] ? 'expanded' : ''}`}>
                                    <p>{member.description}</p>
                                    <button onClick={() => handleReadMore(index)}>
                                        {expanded[index] ? 'Show Less' : 'Read More'}
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamMembers;
