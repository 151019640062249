import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import About from './components/About/About';
import TeamMembers from './components/Team/teammembers';
import TeamMembersDetails from './components/Team/Teammembersdetails';
import Service from './components/service/service';
import Contact from './components/Contact/contact';
import Careers from './components/Career/careers';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/footer';
import 'aos/dist/aos.css';
import AOS from 'aos';
import BottomNavigation from './components/Bottom/bottomNavigation';
import ScrollToTop from './components/ScrollToTop'; 

function App() {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1200,  // Duration of animations
      easing: 'ease-in-out',  // Easing function
      once: false,  // Whether animation should happen only once
      mirror: true,  // Whether elements should animate out while scrolling past them
    });

    AOS.refresh();
  }, []);

  return (
    <Router>
      <ScrollToTop />  {/* Add ScrollToTop here */}
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/team-members" element={<TeamMembers />} />
          <Route path="/team-members/:id" element={<TeamMembersDetails />} />
          <Route path="/service" element={<Service />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/careers" element={<Careers />} />
        </Routes>
        <Footer />
        {isMobile && <BottomNavigation />}
      </div>
    </Router>
  );
}

export default App;
