import React, { useEffect, useState } from "react";
import "./Services.css";
import Serviceshero from "../../assets/servicesection.jpg";

const Services = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(
          "https://cabackend.onrender.com/service/getAllServices"
        );
        const data = await response.json();
        setServices(data.services);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchServices();
  }, []);

  return (
    <div className="services-page">
      <div className="hero-section">
        <img src={Serviceshero} alt="Services Hero" />
        {/* <div className="hero-text">
          <h1>CA Services </h1>
          <ul>
            <li>Accounting and Bookkeeping Services</li>
            <li>Audit & Assurance Services</li>
            <li>Advisory and Consulting</li>
            <li>Payroll Services</li>
            <li>Tax Filing</li>
            <li>Taxation</li>
          </ul>
          <button className="call-button">Call Us +91-8109846688</button>
        </div> */}
      </div>
      <main className="services-list">
        <h1>Our Services</h1>
        <ul>
          {services.map((service, index) => (
            <ServiceItem service={service} key={index} />
          ))}
        </ul>
      </main>
    </div>
  );
};

const ServiceItem = ({ service }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <li
      className="service-item"
      data-aos="fade-up"
      data-aos-anchor-placement="center-bottom"
    >
      {/* <img src={service.image} alt={service.title} className="service-image" /> */}
      <div className="service-content">
        <h2>{service.title}</h2>
        <p className={`service-description ${isExpanded ? "expanded" : ""}`}>
          {service.description}
        </p>
        {service.serviceItem && (
          <ul className={`service-items ${isExpanded ? "expanded" : ""}`}>
            {service.serviceItem.map((item, idx) => (
              <li key={idx}>{item}</li>
            ))}
          </ul>
        )}
        <button className="read-more-button" onClick={toggleReadMore}>
          {isExpanded ? "Read Less" : "Read More"}
        </button>
      </div>
    </li>
  );
};

export default Services;
